import Vue from 'vue';
import Component from 'vue-class-component';
import { DUNATI_DOMAIN, DUNATI_ORGANIZATION_SCHEMA, ROBOT_CONTENT } from '@/main';

@Component({})
export default class DeskCategoryPage extends Vue {

  constructor() {
    super();
    this.$options.dunatiHeadTags = {
      title: 'Escritorios - Muebles de oficina | Dunati',
      metaTags: [
        /* Primaty Tags */
        {
          name: 'description',
          content: 'Series de escritorios. Creamos espacios que potencien el trabajo colaborativo mejorando la expericiencia de las personas.',
        },
        {
          name: 'robot',
          content: ROBOT_CONTENT,
        },
        /* Open Graph / Facebook */
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: `${DUNATI_DOMAIN}/escritorios`,
        },
        {
          property: 'og:title',
          content: 'Escritorios - Muebles de oficina | Dunati',
        },
        {
          property: 'og:description',
          content: 'Somos fabricantes de muebles para oficinas. Creamos espacios que potencien el trabajo colaborativo mejorando la expericiencia de las personas.',
        },
        {
          property: 'og:image',
          content:  `${DUNATI_DOMAIN}/assets/images/home_page/og_home.jpg`,
        },
        /* Twitter */
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:url',
          content: `${DUNATI_DOMAIN}/escritorios`,
        },
      ],
      /*
      metaInfo: {
        link: [
          {
            rel: 'canonical',
            href: `${DUNATI_DOMAIN}/escritorios`
          }
        ]
      }
      */
    };

    this.$options.dunatiJsonld = [
      DUNATI_ORGANIZATION_SCHEMA,
      {
        "@context":"https://schema.org",
        "@type":"ItemList",
        "mainEntityOfPage":{
          "@type":"CollectionPage",
          "@id": `${DUNATI_DOMAIN}/escritorios`
        },
        "itemListElement": [
          {
            "@type": "ListItem",
            "position": 1,
            "url": `${DUNATI_DOMAIN}/escritorios/versa`
          },
          {
            "@type": "ListItem",
            "position": 2,
            "url": `${DUNATI_DOMAIN}/escritorios/diamond`
          },
          {
            "@type": "ListItem",
            "position": 3,
            "url": `${DUNATI_DOMAIN}/escritorios/nordika`
          },
          {
            "@type": "ListItem",
            "position": 4,
            "url": `${DUNATI_DOMAIN}/escritorios/lift`
          },
          {
            "@type": "ListItem",
            "position": 5,
            "url": `${DUNATI_DOMAIN}/escritorios/legacy`
          },
          {
            "@type": "ListItem",
            "position": 6,
            "url": `${DUNATI_DOMAIN}/escritorios/prisma`
          },
          {
            "@type": "ListItem",
            "position": 7,
            "url": `${DUNATI_DOMAIN}/escritorios/arista`
          },
          {
            "@type": "ListItem",
            "position": 8,
            "url": `${DUNATI_DOMAIN}/escritorios/optima`
          }
        ]
      }
    ]
  }
  public mounted() {
    // this.$store.dispatch('changeMenu', 'bg1');
    this.getWidth();
    window.addEventListener('resize', this.getWidth);
  }

  public created() {
  }

  public destroyed() {
    window.removeEventListener('resize', this.getWidth);
  }

  public getWidth() {
    if (window.innerWidth <= 767.98) {
      this.$store.dispatch('changeMenu', 'bg1');
    }
  }

  public toRoute(router: string) {
    this.$router.push({ path: router });
  }
}
